import React, { Component } from 'react';
import { Modal, Upload, Radio, Image, Button, message, Input, Table, Spin, InputNumber, Popconfirm, Select, Checkbox } from 'antd';
import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getShopVideos, getVideoInfo, getVideoPlay, getVideoSub, } from '../../../services/newOder';
import { getMasterManager, getShopManager } from '../../../services/orderCore';
import WorkerModal from '../allOrder/workerModal';
import { getFeeds, postFeedback, getFeedback, mdyFeedback } from '../../../services/feed';
import styles from './index.module.css';
import convert from '../../../utils/convert';
import { getDepartment_Data } from '../../../config/storage';
import { ossUpload, } from '../../../utils/ossUpload.js';
import _ from 'lodash';

const init = {
  id: 0,
  order: 0,
  state: '待处理',
  feed: 0,
  cash: '',
  feed_str: '',
  detail: 0,
  detail_str: '',
  remark: '',
  pic: [],
  vod: [],
  price_shop: 0,
  price_worker: 0,
  content: '',
  checkers: [],
  follows: [],
};
class FeedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      feeds: [],
      info: {},
      current: { ...init },
      workerManegers: [],
      shopManegers: [],
      type: '',
      videoList: [],
    };
    this.baseUrl = getDepartment_Data().service;
    this.workerModal = React.createRef();
  }
  componentDidMount() {
  }
  render() {
    const { visible, type, loading, feeds, current, workerManegers, shopManegers } = this.state;
    let details = feeds.filter(r => r.id === current.feed);
    let curDetail = details[0] ? details[0].details : [];
    return (
      <Modal
        title={'反馈'}
        width={900}
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        footer={
          <div className='row-r w100'>
            <Button onClick={this.onCancel}>取消</Button>
            <Button onClick={this.onOk} type='primary' loading={loading}>确定</Button>
          </div>
        }
      >
        <div className='col-t w100'>
          <div className='row-l w100 margin-b10'>
            <div className='row w110 '>
              <div className='color2'>*</div>
              <div>问题类型：</div>
            </div>
            <Select value={current.feed ? current.feed : null} className='w400' onChange={this.changeInfo.bind(this, 'feed')}>
              {
                feeds.map((item, index) => {
                  return (
                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                  );
                })
              }
            </Select>
          </div>
          <div className='row-l w100 margin-b10'>
            <div className='row w110 '>
              <div className='color2'>*</div>
              <div>子问题类型：</div>
            </div>
            <Select value={current.detail ? current.detail : null} className='w400' onChange={this.changeInfo.bind(this, 'detail')}>
              {
                curDetail.map((item, index) => {
                  return (
                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                  );
                })
              }
            </Select>
          </div>
          <div className='row-l w100 vertical-lt margin-b10'>
            <div className='w110 tc'>问题详情：</div>
            <Input.TextArea value={current.remark} className='w700' rows={4} onChange={this.changeInfo.bind(this, 'remark')} />
          </div>
          <div className='row-l w100 vertical-lt margin-b10'>
            <div className='w110 tc'>图片：</div>
            <div className='row-w w700'>
              <Upload
                type='file'
                beforeUpload={this.onAddPic}
                fileList={[]}
                accept='image/*'
                multiple={true}
              >
                <div className={`row cursor-pointer ${styles['pic-div']}`}>
                  <div className='row h100'>
                    <PlusOutlined />
                    <span>
                      新增图片
                    </span>
                  </div>
                </div>
              </Upload>
              {
                current.pic.map((item, index) => {
                  return (
                    <div key={index} className={`${styles['content']}`}>
                      <div className={`row ${styles['pic-icon']}`}
                        onClick={this.delSignPic.bind(this, index)}
                      >
                        <DeleteOutlined />
                      </div>
                      <img
                        alt='暂无图片'
                        className={`${styles['pic']}`}
                        src={`${this.baseUrl}/upload/${item}`}
                      />
                      <div className={`row ${styles['content-div']}`}>
                        <EyeOutlined
                          className={`${styles['pic-icon-E']}`}
                          onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                          theme='outlined'
                          title='预览'
                        />
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div className='row-l w100 vertical-lt margin-b10'>
            <div className='w110 tc'>视频：</div>
            <div className='row-w vertical-lt w750'>
              <Upload
                type='file'
                beforeUpload={this.uploadVideo}
                customRequest={() => { }}
                fileList={[]}
                multiple={false}
                maxCount={1}
              >
                <div className={`row cursor-pointer ${styles['pic-div']}`}>
                  <div className='row h100'>
                    <PlusOutlined />
                    <span>
                      新增视频
                    </span>
                  </div>
                </div>
              </Upload>
              {
                current.vod.map((item, index) => {
                  return (
                    <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                      <div className={`${styles['content-video']}`}>
                        <video controls='controls' className={styles['content-image']} src={item.url} />
                      </div>
                      <Popconfirm
                        title='您确定要删除该视频吗?'
                        onConfirm={this.delVideo.bind(this, index)}
                        okText='确认'
                        cancelText='取消'
                      >
                        <Button type='danger' className='w100 margin-t5'>删除视频</Button>
                      </Popconfirm>
                    </div>
                  );
                })
              }
            </div>
          </div>

          <div className='row-l w100 vertical-lt margin-b10'>
            <div className='w110 tc'>处理人员：</div>
            <div className='bord1 w600 h90 row-w padding10'>
              {
                current.checkers.map((item, index) => {
                  return (
                    <div className='row bord1 border5 padding5 margin-r10 margin-b10' key={index}>
                      <div className='margin-r10 '>{item.name}</div>
                      {
                        type === 'new' &&
                        <div onClick={this.delDealer.bind(this, index)} className='padding-lr10 cursor-pointer none-select'>X</div>
                      }
                    </div>
                  );
                })
              }
            </div>
            {
              type === 'new' &&
              <Button onClick={this.addDealer} type='primary'>添加</Button>
            }
          </div>
          <div className='row-l w100 vertical-lt margin-b10'>
            <div className='w110 tc'></div>
            <div className='row-l vertical-lt'>
              <div className='col-t vertical-lt'>
                {
                  shopManegers[0] && <div>
                    <Checkbox checked={shopManegers[0].check} onChange={this.changeShop.bind(this, 0)}>同步给商家负责人1</Checkbox>
                  </div>

                }
                {
                  shopManegers[1] && <div>
                    <Checkbox checked={shopManegers[1].check} onChange={this.changeShop.bind(this, 1)}>同步给商家负责人2</Checkbox>
                  </div>
                }
              </div>
              <div className='col-t vertical-lt'>
                {
                  workerManegers[0] && <div>
                    <Checkbox checked={workerManegers[0].check} onChange={this.changeWork.bind(this, 0)}>同步给师傅负责人1</Checkbox>
                  </div>

                }
                {
                  workerManegers[1] && <div>
                    <Checkbox checked={workerManegers[1].check} onChange={this.changeWork.bind(this, 1)}>同步给师傅负责人2</Checkbox>
                  </div>
                }
              </div>

            </div>
          </div>
          {
            current.cash === '是' &&
            <div className='row-l w100 vertical-lt margin-b10'>
              <div className='w110 tc'></div>
              <InputNumber value={current.price_shop} onChange={this.changeInfo.bind(this, 'price_shop')} className='w200 margin-r20' addonBefore='商户金额' />
              <InputNumber value={current.price_worker} onChange={this.changeInfo.bind(this, 'price_worker')} className='w200' addonBefore='师傅金额' />
            </div>
          }
        </div>
        <WorkerModal
          ref={this.workerModal}
          noAll={true}
          updateTable={this.changeDeal}
        />
      </Modal>
    );
  }
  getFeed = async () => {
    let res = await getFeeds();
    if (res.status === 200) {
      this.setState({
        feeds: res.data,
      });
    }
  }
  getManager1 = async (workerid) => {
    let res = await getMasterManager(workerid);
    if (res.status === 200) {
      let workerManegers = res.data.map(item => {
        item.check = false;
        return item;
      });
      this.setState({
        workerManegers,
      });
    }
  }
  getManager2 = async (shopid) => {
    let res = await getShopManager(shopid);
    if (res.status === 200) {
      let shopManegers = res.data.map(item => {
        item.check = false;
        return item;
      });
      this.setState({
        shopManegers,
      });
    }
  }

  changeInfo = (type, e) => {
    const { feeds } = this.state;
    let current = _.cloneDeep(this.state.current);
    switch (type) {
      case 'feed':
        let curFeed = feeds.filter(r => r.id === e);
        current.feed = e;
        current.feed_str = curFeed[0].name;
        current.detail = 0;
        current.detail_str = '';
        current.cash = curFeed[0].cash;

        break;
      case 'detail':
        let details = feeds.filter(r => r.id === current.feed);
        let curDetail = details[0].details.filter(r => r.id === e);
        current.detail = e;
        current.detail_str = curDetail[0].name;
        break;
      case 'remark':
        current.remark = e.target.value;
        break;
      case 'price_shop':
      case 'price_worker':
        current[type] = e ? e : 0;
        break;
    }
    this.setState({
      current,
    });
  }
  //图片备注
  onAddPic = async (file, fileList) => {
    let current = _.cloneDeep(this.state.current)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = current.pic.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      current.pic[leng + i] = name;
    }
    this.setState({
      current
    })
  }
  delSignPic = (e) => {
    let current = _.cloneDeep(this.state.current)
    current.pic.splice(e, 1);
    this.setState({
      current,
    });
  }
  //视频备注
  uploadVideo = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let current = _.cloneDeep(this.state.current);
      current.vod.push({
        videoId: e,
        url: res.data.playURL,
      });
      this.setState({
        current
      });
    }
  }
  delVideo = (index) => {
    let current = _.cloneDeep(this.state.current);
    current.vod.splice(index, 1)
    this.setState({
      current
    });
  }
  //处理人
  delDealer = (index) => {
    let current = _.cloneDeep(this.state.current);
    current.checkers.splice(index, 1)
    this.setState({
      current
    });
  }
  addDealer = () => {
    this.workerModal.current.onShowModal('客服');
  }
  changeDeal = (e) => {
    let current = _.cloneDeep(this.state.current);
    current.checkers.push({
      id: e.id,
      name: e.name,
    });
    this.setState({
      current,
    });
  }
  changeShop = (index, e) => {
    let shopManegers = _.cloneDeep(this.state.shopManegers);
    shopManegers[index].check = e.target.checked;
    this.setState({
      shopManegers
    });
  }
  changeWork = (index, e) => {
    let workerManegers = _.cloneDeep(this.state.workerManegers);
    workerManegers[index].check = e.target.checked;
    this.setState({
      workerManegers
    });
  }

  onOk = async () => {
    const { info, type, shopManegers, workerManegers, } = this.state;
    let current = { ...this.state.current };
    if (current.feed === 0 || current.detail === 0) {
      message.warn('问题不完整');
      return;
    }
    current.vod = JSON.stringify(current.vod.map(r => r.videoId));
    current.pic = JSON.stringify(current.pic);
    if (current.cash !== '是') {
      current.price_shop = 0;
      current.price_worker = 0;
    }
    if (type === 'new') {
      current.order = info.id;
      let checkers = shopManegers.concat(workerManegers).filter(r => r.check).concat(current.checkers);
      const uniqueCheckers = checkers.reduce((acc, cur) => {
        const hasDuplicate = acc.some(item => item.id === cur.id && item.name === cur.name);
        if (!hasDuplicate) {
          acc.push(cur);
        }
        return acc;
      }, []);
      current.checkers = uniqueCheckers.map(item => {
        return {
          checker: item.id,
          name: item.name,
          state: '待处理',
        };
      });
      if (current.checkers.length === 0) {
        message.warn('未选择处理人员');
        return;
      }
      let res = await postFeedback(current);
      if (res.status === 200) {
        this.onCancel();
      }
    } else {
      let res = await mdyFeedback(current);
      if (res.status === 204) {
        this.onCancel();
        this.props.update();
      }
    }

  }
  onCancel = () => {
    this.setState({
      visible: false,
      info: {},
      current: { ...init },
      workerManegers: [],
      shopManegers: [],
      type: '',
    });
  }
  onShow = (e, type) => {
    this.getFeed();
    if (e.workerId) {
      this.getManager1(e.workerId);
      this.getManager2(e.shop);
    }
    if (type === 'edit') {
      this.getFeedbackInfo(e.id);
    }
    this.setState({
      visible: true,
      info: e,
      type,
    });
  }
  getFeedbackInfo = async (id) => {
    let res = await getFeedback(id);
    if (res.status === 200) {
      let current = res.data.f;
      current.pic = JSON.parse(current.pic);
      current.vod = JSON.parse(current.vod);
      current.vod = res.data.f.vod.map(item => {
        return {
          videoId: item,
          videoUrl: ''
        };
      });
      let that = this;
      current.vod.map((item, index) => {
        (async () => {
          let local = index;
          let rsp = await getVideoPlay(item.videoId);
          if (rsp.status === 200) {
            current.vod[local].videoUrl = rsp.data.playURL;
            that.setState({
              current
            });
          }
        })()
      });
      this.setState({
        current,
      });
    }
  }
  preview = (url) => {
    let features = 'height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no';
    window.open(url, 'newW', features);
  }
}
export default FeedModal;