import React, { Component } from 'react';
import { message, Table, Input, Button, DatePicker, Select } from 'antd';
import Search from '../../../components/financeSearch';
import PageTurning from '../../../components/pageTurning';
import { getWithdrawlist, batchWithdraw } from '../../../services/orderFinance';
import { getMasterManager } from '../../../services/orderCore';
import styles from './index.module.css';
import MasterModal from '../../masterManagement/modal';
import WorkerModal from '../../orderCore/allOrder/workerModal';
import Person from '../../../resource/png/person.png';
import Weixinzhifu from '../../../resource/png/weixinzhifu.png';
import Alipay from '../../../resource/png/alipay.png';
import CreditCard from '../../../resource/png/credit-card.png';
import IndexModal from './indexModal';
import Option from '../../../components/option';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { jsToExcel } from '../../../utils/exportExcel';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'
const typeList = ['待支付', '已支付', '已作废'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        state: '待支付',
        type: '全部',
        startDates: moment().subtract(31, 'days').format(dateFormat),
        endDates: moment().format(dateFormat),
        search: '',
        take: 100,
        skip: 0,
      },
      data: [],
      kefuData: {
        id: 0,
        name: '全部',
      },
      selectedRowKeys: [],
      balance: '',
      payLoading: false,
      download: false,
    }
    this.columns = [
      {
        title: '师傅基本信息',
        align: 'center',
        width: 240,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className='row-l vertical-lt margin-l10'>
              <img src={Person} />
              <div onClick={this.onEditMaster.bind(this, record.worker)} className='margin-r10 color3 underline cursor-pointer'>{record.name}</div>
              <div className='color7'>{record.uid}</div>
              <div className='margin-l10 color7'>(账号)</div>
            </div>
          )
        }
      },
      {
        title: '提现金额',
        align: 'center',
        width: 80,
        dataIndex: 'price'
      },
      {
        title: '当前提现账户',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row-l color7 margin-l10'>
              {record.type === '支付宝' && <img src={Alipay} />}
              {record.type === '微信' && <img src={Weixinzhifu} />}
              {record.type !== '微信' && record.type !== '支付宝' && <img src={CreditCard} />}
              <div className='col vertical-lt margin-l10'>
                <div>{record.card_number}</div>
                <div>{record.account_name}</div>
              </div>
            </div>
          )
        }
      },
      {
        title: '备注',
        align: 'center',
        width: 160,
        dataIndex: 'remark'
      },
      {
        title: '打款状态',
        align: 'center',
        width: 100,
        dataIndex: 'state',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={`
                        ${styles['state_box']} 
                        ${text === '待支付' && styles['state_color1']}
                        ${text === '已支付' && styles['state_color2']}
                        ${text === '已作废' && styles['state_color3']}
                        `}>{text}</div>
            </div>
          )
        }
      },
      {
        title: '提交时间',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>{moment(record.submit_time).format('YYYY/MM/DD-HH:mm:ss')}</div>
          )
        }
      },
      {
        title: '确认时间',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>
              {
                record.check_time === "0001-01-01T00:00:00" ? (
                  <div>--</div>
                ) : (
                  <div>{moment(record.check_time).format('YYYY/MM/DD-HH:mm:ss')}</div>
                )
              }
            </div>
          )
        }
      },
      {
        title: '确认客服',
        align: 'center',
        width: 100,
        dataIndex: 'checker'
      },
      {
        title: '操作',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              {
                record.state === '待支付' &&
                <div className={`${styles['button']}`} onClick={this.onWorkerClick.bind(this, record.id, record.state)}>确认打款</div>
              }
            </div>
          )
        }
      },
    ]
    this.pageModal = React.createRef();
    this.indexModal = React.createRef();
    this.workerModal = React.createRef();
    this.masterModal = React.createRef();
  }

  componentDidMount() {
    this.setState({
      startDates: moment().subtract(31, 'days').format(dateFormat),
      endDates: moment().format(dateFormat),
    }, () => this.onSearch());
  }

  render() {
    const { info, data, kefuData, selectedRowKeys,
      balance, payLoading, download } = this.state;
    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      type: 'checkbox',
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <div className={`${styles['fixed']} col back-color0 padding20 vertical-lt`}>
          <div className='row-l'>
            <span className={styles['title_style']}>确认客服：</span>
            <Input disabled={true} className='w198' value={kefuData.name} />
            <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '客服')}>选择</Button>
            <span className='margin-l20'>状态：</span>
            <Select value={info.state} className='w120' onChange={this.changeSum.bind(this, 'state')}>
              {
                typeList.map((item, index) => {
                  return (
                    <Select.Option value={item} key={index}>{item}</Select.Option>
                  );
                })
              }
            </Select>
            <span className={styles['title_style']}>账户选择：</span>
            <Select value={info.type} className='w120' onChange={this.changeSum.bind(this, 'type')}>
              {
                ['全部', '微信', '支付宝', '银行卡', '其他'].map((item, index) => {
                  return (
                    <Select.Option value={item} key={index}>{item}</Select.Option>
                  );
                })
              }
            </Select>
            <span className='margin-l20 '>开始日期：</span>
            <DatePicker value={moment(info.startDates)}
              onChange={this.changeSum.bind(this, 'startDates')}
              allowClear={false} locale={locale} format={dateFormat} />
            <span className='margin-lr5'>结束日期：</span>
            <DatePicker value={moment(info.endDates)}
              onChange={this.changeSum.bind(this, 'endDates')}
              allowClear={false} locale={locale} format={dateFormat} />

          </div>
          <div className='row-sb margin-t10 w100'>
            <div className='row-l'>
              <div>师傅信息:</div>
              <Input placeholder={'请输入姓名或手机号码'}
                className={'margin-l10 margin-r20 w295'}
                value={info.search} onChange={this.changeSum.bind(this, 'search')} />
              <Button type='primary' className={'margin-l20 margin-r20'} onClick={this.toSearch}>搜索</Button>
              <Button onClick={this.onReset}>重置</Button>
              <div className='margin-l20 fz18 bold'>{balance}</div>
            </div>
            <Button loading={download} type='primary' onClick={this.toExcel}>导出Excel</Button>
          </div>
          <Button loading={payLoading} onClick={this.onBatch} className={`${styles['batch']} margin-t10 color0 back-color11 tc fz16 cursor-pointer`}>
            批量打款（{selectedRowKeys.length}）
          </Button>
        </div>
        <div className='padding66'> ...  </div>
        <div className='margin-t10 back-color0'>
          <Table
            columns={this.columns}
            dataSource={data}
            selectedRowKeys={selectedRowKeys}
            rowSelection={rowSelection}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} pageSize={info.take} />
        </div>
        <WorkerModal
          ref={this.workerModal}
          updateTable={this.updateTable}
        />
        <IndexModal
          ref={this.indexModal}
          updateTable={this.onSearch}
        />
        <MasterModal
          ref={this.masterModal}
        />
      </div>
    );
  }
  changeSum = (type, e) => {
    let info = { ...this.state.info };
    switch (type) {
      case 'state':
      case 'type':
        info[type] = e;
        break;
      case 'search':
        info[type] = e.target.value;
        break;
      case 'startDates':
      case 'endDates':
        info[type] = moment(e).format(dateFormat);
        break;
    }
    this.setState({
      info,
    });
  }
  toSearch = () => {
    let info = { ...this.state.info };
    info.skip = 0;
    this.pageModal.current.returnFirst();
    this.setState({
      info,
    }, () => this.onSearch())
  }
  onReset = () => {
    this.pageModal.current.returnFirst();
    this.setState({
      info: {
        state: '待支付',
        type: '全部',
        startDates: moment().subtract(31, 'days').format(dateFormat),
        endDates: moment().format(dateFormat),
        search: '',
        take: 100,
        skip: 0,
      },
      data: [],
      kefuData: {
        id: 0,
        name: '全部',
      },
    }, async () => this.onSearch())
  }
  onWorkerClick = (id, state) => {
    if (state === '已支付' || state === '已作废') {
      message.warn(`提现${state}`)
      return;
    }
    this.indexModal.current.onShowModal(id);
  }
  updateTable = (row, type) => {
    if (row) {
      this.setState({
        kefuData: row,
      });
    }
  }
  //打开modal
  openModal = (e) => {
    this.workerModal.current.onShowModal(e);
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys: selectedRowKeys
    });
  }
  onBatch = async () => {
    const { selectedRowKeys } = this.state;
    this.setState({
      payLoading: true,
    })
    let res = await batchWithdraw(selectedRowKeys);
    if (res.status === 204) {
      this.onSearch();
    } else {
      message.error(res.data);
      this.setState({
        payLoading: false,
      })
      return;
    }
  }
  onSearch = async () => {
    let { info, kefuData, } = this.state;
    let res = await getWithdrawlist(info.search, kefuData.id, info.state, info.type, info.startDates, info.endDates, info.skip, info.take);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      data: res.data.data,
      balance: info.state + '金额：' + res.data.balance,
      selectedRowKeys: [],
      payLoading: false,
    })
  }
  toExcel = async () => {
    let { info, kefuData, } = this.state;
    let down = true;
    let dataSource = [];
    this.setState({
      download: true,
    })
    let skip = 0;
    let take = 200;
    let excelData = [];
    while (down === true) {
      let res = await getWithdrawlist(info.search, kefuData.id, info.state, info.type, info.startDates, info.endDates, skip, take);
      if (res.status === 200) {
        dataSource = dataSource.concat(res.data.data)
        down = res.data.data.length < take ? false : true;
        skip = skip + res.data.data.length;
      } else {
        down = false;
      }
    }
    excelData = dataSource.map((item, index) => {
      return {
        locate: index + 1,
        info: item.name + '-' + item.uid,
        type: item.type ? item.type : '',
        card_number: item.type === '银行卡' ? item.card_number + '&nbsp' : item.card_number,
        account_name: item.account_name ? item.account_name : '',
        price: item.price,
      }
    })
    const columnsBill = [
      {
        title: '序号',
        align: 'center',
        width: 100,
        key: 'locate',
        type: 'locate',
      },
      {
        title: '师傅信息',
        align: 'center',
        width: 100,
        key: 'info',
        type: 'key',
      },
      {
        title: '账户类型',
        align: 'center',
        width: 100,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: '账号',
        align: 'center',
        width: 100,
        dataIndex: 'type',
        key: 'card_number',
      },
      {
        title: '收款人',
        align: 'center',
        width: 100,
        dataIndex: 'type',
        key: 'account_name',
      },
      {
        title: '金额',
        align: 'center',
        width: 100,
        dataIndex: 'price',
        key: 'price',
      }
    ]
    let fileName = '师傅提现列表';
    jsToExcel(columnsBill, excelData, fileName, fileName);
    this.setState({
      download: false,
    })

  }
  pageMaps = ({ skip, take }) => {
    let info = { ...this.state.info };
    info.skip = skip;
    this.setState({
      info,
    }, () => this.onSearch())
  }
  //修改师傅信息
  onEditMaster = async (e) => {
    if (e) {
      let rsp = await getMasterManager(e)
      if (rsp.status === 200) {
        let data = {
          type: 'edit',
          id: e,
          manager: rsp.data[0] ? rsp.data[0].name : '',
        }
        this.masterModal.current.showDrawer(data);
      }
    }
  }

}

export default Index;